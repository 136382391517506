import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const SanitairPage = () => {
  const data = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "images/sanitair/hero.jpg" }) {
        ...heroImage
      }
      sanitair: file(
        relativePath: { eq: "images/sanitair/services/badkamer.jpg" }
      ) {
        ...columnImage
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Sanitair - Uytterhaegen Jan BV" pathname="/sanitair" />
      <BackgroundImage
        Tag="section"
        className="main-hero"
        fluid={data.main.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <div className="main-hero-content">
          <div className="main-hero-content-title">
            <h2>Sanitair</h2>
          </div>
        </div>
      </BackgroundImage>
      <div className="container">
        <div className="wrapper">
          <div className="content">
            <section>
              <div className="two-column">
                <div className="column-60">
                  <h1>Sanitair en badkamers</h1>
                  <p>
                    Combinatie van ervaring, kennis, inzicht en persoonlijke
                    bekommernis garanderen een kwalitatieve badkamerrenovatie
                  </p>
                  <h3>Hoe gaan wij te werk bij badkamerrenovatie?</h3>
                  <ul>
                    <li>
                      <FontAwesomeIcon
                        className="facebook"
                        icon={faAngleRight}
                      />
                      Ga naar een toonzaal waar wij mee samenwerken. Laat een
                      prijsofferte maken volgens uw smaak en budget.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="facebook"
                        icon={faAngleRight}
                      />
                      Laat ons weten dat je een badkamer gekozen hebt, wij maken
                      dan een afspraken en komen langs om alles te overlopen.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="facebook"
                        icon={faAngleRight}
                      />
                      Na overleg en gezien te hebben dat alles technisch
                      haalbaar is, bestellen we de sanitaire meubels en worden
                      deze geplaatst.
                    </li>
                  </ul>
                </div>
                <div className="column-40">
                  <Img
                    alt={data.sanitair.childImageSharp.name}
                    fluid={data.sanitair.childImageSharp.fluid}
                  />
                </div>
                <section>
                  <h3>Waterbehandeling : Plaatsen van waterverzachter</h3>
                  <p>
                    Zowat overal in Vlaanderen is er een ernstig probleem
                    aangaande de hardheid van het leidingwater. Of je nu
                    eigenaar bent van een woning, of je huurt een woning,
                    iedereen heeft er baat bij om zijn eigen apparaten te
                    beschermen tegen de hoge kalkwaarden in het drinkwater.
                  </p>
                  <p>
                    Zeker als eigenaar van een woning kan je stellen dat deze
                    zo'n installatie terugbetalen, of ze die nu hebben of niet -
                    alle apparatuur die met water werkt, gaan op termijn kapot.
                    Van de kraan in de keuken, tot de thermostatische mengkraan
                    in de badkamer, tot de boiler! Weliswaar gaat het langzaam,
                    maar op termijn betaal je sowieso een waterontharder, eer je
                    er nu één hebt of niet.
                  </p>
                  <p>
                    Dus je kan maar beter je dure apparaten beschermen,
                    vooraleer je deze moet vernieuwen.
                  </p>
                </section>
                <section>
                  <h3>Waterrecuperatie : Plaatsen van regenwaterpomp</h3>
                  <p>
                    Regenwaterrecuperatie houdt in dat men regenwater gaat
                    gebruiken voor allerlei toepassingen binnen- en buitenshuis,
                    onder andere voor het toilet, de wasmachine, om te poetsen
                    of om de moestuin water te geven. Op die manier blijft een
                    aanzienlijke hoeveelheid drinkwater gespaard, waardoor je
                    tot maar liefst 50% kan besparen op je factuur.
                  </p>
                  <p>
                    Regenwaterrecuperatie levert dus zowel een ecologische als
                    een economische bijdrage. Door een bestaande woning uit te
                    rusten met een regenwaterinstallatie zal bovendien ook de
                    waarde van het huis stijgen.
                  </p>
                </section>
              </div>
            </section>
            {/* <section>
              <h1>Enkele van onze realisaties</h1>
              <Lightbox
                thumbs={data.thumbs.edges}
                images={data.realisaties.edges}
              />
            </section> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SanitairPage
